import addListener from '../utils/addListener';


  var flipCards = document.querySelectorAll('.flip_card');

  if (flipCards) {
    [...flipCards].forEach((el) => {
      var buttons = el.querySelectorAll('.do-flip');
      [...buttons].forEach((button) => {
        button.addEventListener('click', () => {
          addListener('(min-width: 1000px)', (mediaQuery) => {
            if (mediaQuery.matches) {
              el.classList.toggle('is-flipped');
            }
          });
        });
      });
    });
  }

  function handleLargeScreen() {
    // Function implementation
  }

  function checkBrowserWidth() {
    addListener('(min-width: 1000px)', (mediaQuery) => {
      if (mediaQuery.matches) {
        handleLargeScreen();
      } else {
        if (flipCards) {
          [...flipCards].forEach((el) => {
            el.classList.remove('is-flipped');
          });
        }
      }
    });
  }

  // check browser width on resize
  window.addEventListener('resize', function () {
    checkBrowserWidth();
  });

  // check browser width on page load
  checkBrowserWidth();

