const filterElement = document.querySelector('[data-component="projectCatalog"]');
if (filterElement) {
  const submitToUrl = '/projects/ajax?';
  const xhr = new XMLHttpRequest();

  const filterResults = document.querySelector('[data-content=replace]');
  const loadMoreResultsButtonDesignBuild = document.querySelector('[data-append-designbuild]');
  
  if (filterResults) {
    let lastProjectResult = filterResults.lastElementChild ? filterResults.lastElementChild.dataset.remaining : null;
    let paramsString = '';
    let queryString = new URLSearchParams(paramsString);
  }

  function submitQueryAndFetchResults(url, query, offset) {
    if (filterResults && filterResults.children) {
      let initialResultsCount = filterResults.children.length;
    }

    xhr.open('GET', url + query);

    xhr.onload = function () {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          let lastProjectResult = filterResults.lastElementChild ? filterResults.lastElementChild.dataset.remaining : null;

          if (offset) {
            let currentResults = filterResults.innerHTML;
            filterResults.innerHTML = currentResults + xhr.responseText;
          } else {
            filterResults.innerHTML = xhr.responseText;
          }

          if (lastProjectResult > 0) {
            loadMoreResultsButtonDesignBuild.removeAttribute('disabled');
          } else {
            loadMoreResultsButtonDesignBuild.setAttribute('disabled', 'disabled');
          }

        }
        let latestResultsCount = '';
        if (filterResults) {
          latestResultsCount = filterResults.children.length;
        }

      }
    };

    xhr.send();
  }

  function loadMoreButtonReset(e) {
    loadMoreResultsButtonDesignBuild.dataset.offset = 0;
  }

  function handleLoadMoreResults(e) {
    let paramsString = '';
    let searchParams = new URLSearchParams(paramsString);
    let clickedButton = this;
    let projectType = clickedButton.dataset.buildingUse;
    let page = parseInt(clickedButton.dataset.page) + parseInt(clickedButton.dataset.limit);

    clickedButton.dataset.page = page;

    searchParams.set('page', page);
    searchParams.set('fields[' + clickedButton.dataset.projectType + '][]', projectType);

    submitQueryAndFetchResults(submitToUrl, searchParams, true);
  }

  if (loadMoreResultsButtonDesignBuild) {
    loadMoreResultsButtonDesignBuild.addEventListener('click', handleLoadMoreResults);
  }
}

