
const submitToUrl = '/ajax/review?';
const xhr = new XMLHttpRequest();
const reviewResults = document.querySelector('[data-component=reviews]');
const loadMoreResultsButton = document.querySelector('[data-append-reviews]');

function submitQueryAndFetchResults(url, query, offset) {

  xhr.open('GET', url+query);

  xhr.onload = function () {
    if (xhr.readyState === xhr.DONE) {
      if (xhr.status === 200) {
        let currentResults = reviewResults.innerHTML;
        reviewResults.innerHTML = currentResults + xhr.responseText;
      }
    }
    loadMoreResultsButton.removeAttribute('disabled');

    let resultCount = reviewResults.getElementsByClassName('review_card_wrapper').length;

    if(resultCount == loadMoreResultsButton.dataset.reviewCount) {
      loadMoreResultsButton.disabled = 'disabled';
      loadMoreResultsButton.innerHTML = 'No More Results';
    }

  };

  xhr.send();
}

function loadMoreButtonReset(e) {
  loadMoreResultsButton.dataset.page = 2;
}

function handleLoadMoreResults(e) {
  let paramsString = window.location.search;
  let searchParams = new URLSearchParams(paramsString);
  let clickedButton = this;
  let loadPage = parseInt(clickedButton.dataset.page) + 1;
  clickedButton.dataset.page = loadPage;
  searchParams.set('page', loadPage);

  if(clickedButton.dataset.relatedTo) {
    let relatedTo = parseInt(clickedButton.dataset.relatedTo);
    searchParams.set('relatedTo', relatedTo);
  }

  let limit = parseInt(clickedButton.dataset.limit);
  searchParams.set('limit', limit);

  submitQueryAndFetchResults(submitToUrl, searchParams, true);
  loadMoreResultsButton.setAttribute('disabled','disabled');

}

if (loadMoreResultsButton) {
  loadMoreResultsButton.addEventListener('click', handleLoadMoreResults);
}
