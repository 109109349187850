let dataLayer = window.dataLayer || [];
let destination = false;

let googleMap = null;
let markers = [];
let markerKeys = [];
let infoWindows = [];

const locationMap = document.querySelector('[data-component="locationsMap"]');
const aside = locationMap ? locationMap.querySelector('aside') : null;

const resetTrigger = aside ? aside.querySelector('[data-location-reset]') : null;
const postalCodeLookupContainer = aside ? aside.querySelector('[data-postal-lookup]') : null;
const result = aside ? aside.querySelector('[data-return-destination]') : null;
const zipInput = aside ? aside.querySelector('[name="zip"]') : null;

const mapStyle = [
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#d3d3d3"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "color": "#808080"
      },
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#b3b3b3"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ffffff"
      },
      {
        "weight": 1.8
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#d7d7d7"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#ebebeb"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#a7a7a7"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#efefef"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#696969"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "visibility": "on"
      },
      {
        "color": "#737373"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#d6d6d6"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {},
  {
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  }
];

if (locationMap && aside && resetTrigger && postalCodeLookupContainer && result && zipInput) {
  

  function initializeMap() {
    setTimeout(() => {
      const map = document.querySelector('.gm-map');
      const mapId = map.getAttribute('id');
      googleMap = googleMaps.getMap(mapId);

      buildMap(googleMap);
    }, 2500);
    const blocker = document.querySelector('.map-blocker');
    setTimeout(() => {
      blocker.classList.add('is-hidden');
    }, 2800);
  }

  function buildMap(googleMap) {
    if (resetTrigger) {
      resetTrigger.classList.add('is-hidden');
      resetTrigger.addEventListener('click', () => resetMap(googleMap, markers, markerKeys));
    }
    googleMap.styles(mapStyle);
    setUpMarkers();
    setUpZipSearchLookUp();
  }

  function setUpMarkers() {
    markers = Object.values(googleMap._markers);
    markerKeys = Object.keys(googleMap._markers);
    infoWindows = Object.values(googleMap._infoWindows);

    markers.forEach(function (location, index) {
      const markerId = markerKeys[index];

      location.addListener('click', () => {
        if (destination) {
          showDestinationInSidebar();
          const infoWindow = googleMap.getInfoWindow(markerId);
          console.log(infoWindow);
          resetMarkers(markerId);
          const locationLat = location.getPosition().lat();
          const locationLng = location.getPosition().lng();
          googleMap.center({
            lat: locationLat,
            lng: locationLng
          }).zoom(9);
          if (infoWindow) {
            infoWindow.close();
          }

          var txt = document.createElement("textarea");
          if (txt) {
            txt.innerHTML = infoWindow.content;
            destination.innerHTML = txt.value;
          }

          let dataLayerElement = destination.querySelector('[data-city-state]');

          dataLayer.push({
            'event': 'find a location',
            'e_action': 'map pin click',
            'e_label': dataLayerElement.dataset.cityState
          });
        } else {
          var txt = document.createElement("textarea");
          txt.innerHTML = googleMaps.getInfoWindow(location).content;
          googleMap.openInfoWindow(markerId).setContent(txt.value);
        }
      });

      location.addListener('mouseover', function (el) {
        googleMap.closeInfoWindow('*');
        googleMap.openInfoWindow(markerId);
      });
    });
  }

  function setUpZipSearchLookUp() {
    const serviceAreaZipSearch = document.querySelector('[data-form="map"]');
    if (serviceAreaZipSearch) {
      const xhr = new XMLHttpRequest();
      destination = document.querySelector('[data-return-destination="' + serviceAreaZipSearch.dataset.returnId + '"]');

      serviceAreaZipSearch.addEventListener("submit", (e) => {
        e.preventDefault();

        xhr.open('GET', serviceAreaZipSearch.action + '?zip=' + serviceAreaZipSearch.elements.zip.value + '&cookie=' + serviceAreaZipSearch.elements.cookie.value);
        xhr.onload = function () {
          if (xhr.readyState === xhr.DONE) {
            if (xhr.status === 200) {
              // Hide Zipcode search
              postalCodeLookupContainer.classList.add('is-hidden');
              resetTrigger.classList.remove('is-hidden');
              destination.classList.remove('is-hidden');

              destination.innerHTML = xhr.responseText;
              let entryId = destination.querySelector('[data-id]');
              let lat = destination.querySelector('[data-lat]');
              let lng = destination.querySelector('[data-lng]');
              let dataLayerElement = destination.querySelector('[data-city-state]');
              let myLatlng = { lat: parseFloat(lat.dataset.lat), lng: parseFloat(lng.dataset.lng) };
              let markerId = entryId.dataset.id + '-address';
              resetMarkers(markerId);

              centerMap(myLatlng, googleMap);
              //resetMarkers(locations, locationResult);

              dataLayer.push({
                'event': 'find a location',
                'e_action': 'search zip code',
                'e_label': dataLayerElement.dataset.cityState,
                'zip_code': serviceAreaZipSearch.elements.zip.value
              });
            }
          }
        };

        xhr.send();
      });
    }
  }

  function showDestinationInSidebar() {
    resetTrigger.classList.remove('is-hidden');
    postalCodeLookupContainer.classList.add('is-hidden');
    result.classList.remove('is-hidden');
  }

  function hideDestinationInSidebar() {
    resetTrigger.classList.add('is-hidden');
    postalCodeLookupContainer.classList.remove('is-hidden');
    result.classList.add('is-hidden');
  }

  function resetMap(map, markers, markerKeys) {
    var bounds = new google.maps.LatLngBounds();
    markers.forEach((marker, index) => {
      bounds.extend(marker.getPosition());
      const markerId = markerKeys[index];
      map.setMarkerIcon(markerId, '/dist/images/map-dot.svg');
    });

    infoWindows.forEach((infoWindow, index) => {
      infoWindow.close();
    });

    googleMap.fit();
    hideDestinationInSidebar();

    zipInput.value = '';
  }

  function resetMarkers(locationId) {
    [...markers].forEach((marker, index) => {
      const markerId = markerKeys[index];
      googleMap.setMarkerIcon(markerId, '/dist/images/map-dot.svg');
    });
    googleMap.setMarkerIcon(locationId, '/dist/images/map-dot-selected.svg');
  }

  function centerMap(latLng, googleMap) {
    googleMap.zoom(9).center(latLng);
  }

  function isGoogleMapsLoaded() {
    const intervalId = setInterval(function () {
      if (typeof googleMaps === 'undefined') {
        // Continue checking after 500ms
        console.log('googleMaps is undefined');
      } else {
        console.log('googleMaps is defined');
        clearInterval(intervalId); // Clear the interval once googleMaps is defined
        initializeMap();
      }
    }, 500);
  }

  googleMaps.init();
  isGoogleMapsLoaded();
}

