
const repairCapabilities = document.querySelectorAll('[data-capability]');
const buildingAdditions = document.querySelectorAll('[data-additions]');

function handleCapabilityHover(e) {
  let hoveredCapability = this;

  if(hoveredCapability.getAttribute("data-additions")) {
    exit;
  }

  [...buildingAdditions].forEach((el) => {
    el.setAttribute("data-status", '');
  });

  [...repairCapabilities].forEach((el) => {
    if(el.getAttribute("data-capability") == hoveredCapability.getAttribute("data-capability")) {
      el.setAttribute("data-status", 'is-active');
    } else {
      el.setAttribute("data-status", '');
    }
  });
}

function handleAdditionsHover(e) {
  let hoveredAddition = this;

  [...repairCapabilities].forEach((el) => {
    el.setAttribute("data-status", '');
  });

  [...buildingAdditions].forEach((el) => {
    el.setAttribute("data-status", 'is-active');
  });
}

[...repairCapabilities].forEach((el) => {
  el.addEventListener('mouseover', handleCapabilityHover);
});

[...buildingAdditions].forEach((el) => {
  el.addEventListener('mouseover', handleAdditionsHover);
});

