import '../vendor/node_modules/element-matches-polyfill/index.js';
import './components/phoneMask'

let stickyElements = document.querySelectorAll('[data-component="sticky"]');
let stickyFilter = document.querySelectorAll('[data-sticky-filter]');
let offsetNavigationHeight = document.querySelector('.navbar.global-header').offsetHeight;

if (/Android/i.test(navigator.userAgent)) {
    const html = document.querySelector('html');
    html.classList.add('android');
}