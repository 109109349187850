const page = document.querySelector('html');
const iconNav = document.querySelector('[data-component="iconNav"]');
let iconFormTriggers = '';
let closeFormTriggers = '';
if (iconNav) {
  iconFormTriggers = iconNav.querySelectorAll('[data-trigger]');
  closeFormTriggers = iconNav.querySelectorAll('[data-close]');
}
const xhr = new XMLHttpRequest();
let serviceAreaZipSearch = document.querySelector('[data-form=serviceLocation]');
let serviceAreaZipResult = serviceAreaZipSearch ? serviceAreaZipSearch.querySelector('.result') : null;
let dataLayer = window.dataLayer || [];


function handleIconForms(trigger) {
  trigger.addEventListener('click', (el) => {
    let target = document.querySelector('[data-target="' + trigger.dataset.trigger + '"]');
    let otherFormItems = document.querySelectorAll('.iconNav ._input.is-active');
    if (trigger.dataset.disabled === 'true') {
      target.focus();
      el.preventDefault();
      trigger.dataset.disabled = 'false';

      Array.from(otherFormItems).forEach((el) => {
        let resetFormTrigger = document.querySelector('[data-trigger="' + el.dataset.target + '"]');
        el.classList.toggle('is-active');
        el.parentNode.classList.toggle('is-active');
        resetFormTrigger.dataset.disabled = 'true';
      });

      target.classList.toggle('is-active');
      target.parentNode.classList.toggle('is-active');
    }
  });
}

function handleClosingForms(trigger, serviceAreaZipResult) {
  trigger.addEventListener('click', (el) => {
    let resetFormTrigger = document.querySelector('[data-trigger="' + trigger.dataset.close + '"]');
    let target = document.querySelector('[data-target="' + trigger.dataset.close + '"]');
    let serviceAreaZipResult = serviceAreaZipSearch ? serviceAreaZipSearch.querySelector('.result') : null;

    if (resetFormTrigger && target && serviceAreaZipResult) {
      resetFormTrigger.dataset.disabled = 'true';
      target.value = '';
      target.classList.remove('is-active');
      target.parentNode.classList.remove('is-active');

      serviceAreaZipResult.classList.remove('has-result');
    }
  });
}

if (iconFormTriggers) {
  Array.from(iconFormTriggers).forEach((el) => {
    handleIconForms(el);
  });
};

if (closeFormTriggers)  {
  Array.from(closeFormTriggers).forEach((el) => {
    handleClosingForms(el, serviceAreaZipResult);
  });
}

if (serviceAreaZipSearch) {
  serviceAreaZipSearch.addEventListener('submit', function (e) {
    e.preventDefault();

    xhr.open('GET', serviceAreaZipSearch.action + '?zip=' + serviceAreaZipSearch.elements.zip.value);

    xhr.onload = function () {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200 && serviceAreaZipResult) {
          serviceAreaZipResult.innerHTML = xhr.responseText;
          serviceAreaZipResult.classList.add('has-result');
          let cityState = serviceAreaZipResult.querySelector('[data-city-state');

          if (cityState) {
            dataLayer.push({
              'event': 'find a location',
              'e_action': 'search zip code',
              'e_label': cityState.dataset.cityState,
              'zip_code': serviceAreaZipSearch.elements.zip.value
            });
          }
        }
      }
    };

    xhr.send();
  });
}

