var searchTrigger = document.querySelectorAll('[data-component=search]');
var searchForm = document.querySelector('.global-search');
var searchField = document.querySelector('[name=q]');
var searchContainer = document.querySelectorAll('.global-search__container.-mobile');
var headers = document.querySelectorAll('.global-header');
const searchHighlight = document.querySelector('[data-highlight]');
const mobileSearchButton = document.querySelector('[data-search-trigger="mobile"]');
const mobileSearchClose = document.querySelector('[data-search-close="mobile"]');
const mobileSearchContainer = document.querySelector('[data-mobile-search');
const searchResultsContainer = document.querySelector('[data-search-container]');
const scrollerContainer = document.querySelector('[data-results]');
const loadingIndicator = document.querySelector('[data-loading-indicator]');
const clearMobileSearch = document.querySelector('[data-clear="mobileSearch"]');
const xhr = new XMLHttpRequest();
let isRunning = false;

Array.from(searchTrigger, function(el) {
  el.addEventListener('click', function() {
    toggleSearchField();
  });
});

function toggleSearchField() {
  Array.from(headers, function(el) {
    toggleClass(el, '-search');
  });
  Array.from(searchContainer, function(el) {
    toggleClass(el, '-expanded');
  });
  return false;
}

function showSearchField() {
  searchForm.removeAttribute('hidden');
  searchField.focus();
}

function hideSearchField() {
  searchForm.setAttribute('hidden', '');
  searchField.blur();
}

function toggleClasses(elements, str) {
  Array.from(elements, function(el) {
    toggleClass(el, str);
  });
}

function toggleClass(el, str) {
  if (hasClass(el, str)) {
    removeClass(el, str);
  } else {
    addClass(el, str);
  }
}

function hasClass(el, str) {
  return el.className.indexOf(str) !== -1;
}

function addClass(el, str) {
  el.className = el.className + ' ' + str;
}

function removeClass(el, str) {
  el.className = el.className.replace(' ' + str,'');
}

function highlights() {
  const searchQuery = searchHighlight.getAttribute('data-highlight');
  if (searchQuery) {
    const boxes = document.querySelectorAll('.box');
    if (boxes) {
      Array.from(boxes, function(el) {
        const body = el.querySelector('.content p');
        if (body) {
          const bodyText = body.textContent;
          const highlightedText = bodyText.replace(new RegExp(searchQuery, 'gi'), '<span style="font-weight:bold;">$&</span>');
          body.innerHTML = highlightedText;
        }
      });
    }
  }
}

if (searchHighlight) {
  highlights();
}

if (mobileSearchButton && mobileSearchContainer) {
  mobileSearchButton.addEventListener('click', function() {
    toggleClasses([mobileSearchButton, mobileSearchContainer], 'is-open');
  });
  const mobileSearchClose = mobileSearchContainer.querySelector('[data-search-close]');
  if (mobileSearchClose) {
    mobileSearchClose.addEventListener('click', function() {
      toggleClasses([mobileSearchButton, mobileSearchContainer], 'is-open');
    });
  }
}

if (mobileSearchClose) {
  mobileSearchClose.addEventListener('click', function() {
    toggleClasses([mobileSearchButton, mobileSearchContainer], 'is-open');
  });
}
if (clearMobileSearch) {
  clearMobileSearch.addEventListener('click', function() {
    const searchField = mobileSearchContainer.querySelector('input');
    searchField.value = '';
    searchField.placeholder = 'Search Projects';
    const resultsContainer = document.querySelector('.search-results');
    if (resultsContainer) {
      resultsContainer.remove();
    }
  });
}

// Function to check if the bottom of an element is in the viewport
function isElementBottomInViewport(el) {
  var rect = el.getBoundingClientRect();
  return rect.bottom <= window.innerHeight;
}

function handleLoadMoreResults() {
  isRunning = true;
  loadingIndicator.classList.add('is-visible');
  let searchPage = searchResultsContainer.dataset.searchPage;
  let searchQuery = searchResultsContainer.dataset.highlight;
  submitQueryAndFetchResults('/search/search?q=', searchQuery, '&page=' + searchPage)
  currentPage = Number(searchPage);
  searchResultsContainer.setAttribute('data-search-page', Number(currentPage + 1));
}


function submitQueryAndFetchResults(url, query, page) {
  xhr.open('GET', url + query + page);

  xhr.onload = function () {
    if (xhr.readyState === xhr.DONE) {
      if (xhr.status === 200) {
        loadingIndicator.classList.remove('is-visible');
        let currentResults = searchResultsContainer.innerHTML;
        searchResultsContainer.innerHTML = currentResults + xhr.responseText;
        isRunning = false;
        highlights();
      }
    }
  };

  xhr.send();
}

// Function to handle scroll events
function handleScroll() {
  if (scrollerContainer) { 
    if (isElementBottomInViewport(scrollerContainer)) {
      // Trigger lazy loading
      if (!isRunning) {
        handleLoadMoreResults();
      }
    }
  }
}

if (searchResultsContainer) {
  // Attach the scroll event listener
  window.addEventListener('scroll',
    handleScroll
  );
}
