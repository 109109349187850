import smoothScrollTo from '../utils/smoothScrollTo';


const triggerIndicator = document.querySelector('[data-mobiledropdown]');
let tabsElement = document.querySelector('[data-component=tabs]');
let tabsUl = '';
let tabHeadings = '';
let currentTab = '';
let tabsBoundingRect = '';
if (tabsElement) {
  tabsUl = tabsElement.querySelector('ul');
  tabHeadings = tabsElement.querySelectorAll('[data-tab]');
  currentTab = tabsElement.querySelector('li.is-active a, li.is-active button');
}
if (tabsUl) {
  tabsBoundingRect = tabsUl.getBoundingClientRect();
}
let tabBar = document.querySelector('[data-bar]');
let resizeTaskId = null;
let resizeTriggerDelay = 90;

calculateActiveStateBar(currentTab, tabsElement);

if (triggerIndicator) {
  mobileDropdown();
}

function calculateActiveStateBar(currentTab, tabsElement, scrollOffset) {
  if(tabBar) {
    let rect = currentTab.getBoundingClientRect();
    let barWidth = currentTab.offsetWidth;
    let tabsBoundingRect = tabsUl.getBoundingClientRect();
    let barPositionLeft = rect.left - tabsBoundingRect.left;

    if(scrollOffset) {
      let tabBarTransformX = window.getComputedStyle(tabBar).getPropertyValue('transform').match(/(-?[0-9\.]+)/g)[4];
      tabBar.style.transform = 'translateX('+barPositionLeft+'px)';
    } else {
      tabBar.style.width = barWidth+"px";
      tabBar.style.transform = 'translate('+barPositionLeft+'px)';
    }
  }

}

function handleTabClick(e) {

  e.preventDefault();

  [...tabHeadings].forEach((el) => {
    el.classList.remove('is-active');
    el.parentElement.classList.remove('is-active');
  });

  this.classList.add('is-active');
  this.parentElement.classList.add('is-active');

  if (this.classList.contains('is--anchor')) {
    var target = this.getAttribute('href').replace('#','');
    var targetElement = document.getElementById(target);
    smoothScrollTo(targetElement, 200);
  }

  calculateActiveStateBar(this, tabsElement);

  if (triggerIndicator) {
    tabsElement.classList.toggle('is-active');
    const elementText = this.textContent;
    const trigger = triggerIndicator.querySelector('.tab-heading');
    const windowWidth = window.innerWidth;
    if (windowWidth <= 999) {
      trigger.textContent = elementText;
    }
  }

  return false;
}

if (tabHeadings) {
  [...tabHeadings].forEach((el) => {
    el.addEventListener('click', handleTabClick);
  });
}

window.addEventListener('popstate', function(event) {
  let activeTab = window.location.pathname.split('/');
  activeTab = activeTab[2];

  if(activeTab != null) {
    let tabsToActivate = document.querySelectorAll('a[data-project="'+activeTab+'"]');
    if (tabsToActivate) {
      [...tabsToActivate].forEach((el) => {
        el.parentNode.classList.add('is-active');
        calculateActiveStateBar(el, tabsElement);
      });
    }
  }

});

let scrollLeft;

if (tabsUl) {
  tabsUl.addEventListener('scroll', evt => {
    handleTabAdjustments(40)
  });
}

window.addEventListener('resize', evt => {
  handleTabAdjustments(resizeTriggerDelay)
});

function handleTabAdjustments(speed) {
  resizeTaskId = setTimeout(() => {
    let tabsElement = document.querySelector('[data-component=tabs]');
    let currentTab = document.querySelector('[data-component=tabs] li.is-active a');
    calculateActiveStateBar(currentTab, tabsElement);
  },
  speed);
  if (triggerIndicator) {
    const trigger = triggerIndicator.querySelector('.tab-heading');
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1000) {
      trigger.textContent = 'Filter By Use:';
    }
  }
}

function mobileDropdown(){
  const trigger = triggerIndicator.querySelector('.tab-heading');
  if (trigger) {
    trigger.addEventListener('click', function(e) {
      e.preventDefault();
      this.parentNode.classList.toggle('is-active');
    });
  }
}
