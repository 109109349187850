import jquery from '../utils/jquery.min';
window.jQuery = jquery;
window.$ = jquery;

const getStartedSection = document.querySelector('[data-component="getStarted"]');

if (getStartedSection) {
  
  //todo - use the wrapper attribute [data-field-group-name] on the parent div to scope of functions

  const buildingStatusSelect = getStartedSection.querySelector('[name="building-status-dropddown"]');
  const buildingStatusColumn = getStartedSection.querySelector('.mbi_buildingsitestatus-columns');
  const buildingStatusRadios = getStartedSection.querySelectorAll('input.building-status-radio');
  const buildingStatusRadiosWrapper = getStartedSection.querySelector('.building-status-group');

  const buildingCategorySelect = getStartedSection.querySelector('[name="building-category-dropddown"]');
  const buildingCategoryRadios = getStartedSection.querySelectorAll('input.building-category-radio');
  const buildingCategoryRadiosWrapper = getStartedSection.querySelector('.building-category-group');

  const buildingUseSelect = getStartedSection.querySelector('[name="Building_Use_2"]');

  const buildingUseOptions = getStartedSection.querySelector('[data-buildingUse]');
  const buildingUseText = getStartedSection.querySelector('[name="use"]');
  const formRedirectUrl = getStartedSection.querySelector('[name="formReturnUrl"]');
  const form = getStartedSection.querySelector('form');
  const clickDimensionsUseFieldNames = {
    '1': 'Commercial_Building_Use_2*',
    '2': 'Equestrian_Building_Use_2*',
    '3': 'Farm_Building_Use_2*',
    '4': 'Homes_Building_Use_2*',
    '5': 'Repairs_Building_Use_2*', //this one isn't actually used by click dimensions
    '6': 'Suburban_Building_Use_2*',
  };
  let dataLayer = window.dataLayer || [];

  if (buildingCategoryRadios.length > 0) {
    [...buildingCategoryRadios].forEach((field) => field.addEventListener('click', handleCategoryRadio));
  }

  if (buildingStatusRadios.length > 0) {
    [...buildingStatusRadios].forEach((field) => field.addEventListener('click', handleStatusRadio));
  }

  if (buildingStatusSelect) {
    buildingStatusSelect.addEventListener('change', handleStatusSelect);
    if (buildingStatusSelect.value) {
      updateStatusRadios(buildingStatusSelect.value, false);
    }

    if (buildingUseText.value) {
      let selection = buildingUseSelect.querySelector('[value="' + buildingUseText.value + '"]');
      if (selection) {
        buildingUseSelect.value = selection.value;
      }
    }
  }

  if (buildingCategorySelect) {
    buildingCategorySelect.addEventListener('change', handleCategorySelect);
    if (buildingCategorySelect.value) {
      updateCategoryRadios(buildingCategorySelect.value, false);
    }

    buildingUseSelect.addEventListener('change', handleUseSelect);

    if (buildingUseText.value) {
      let selection = buildingUseSelect.querySelector('[value="' + buildingUseText.value + '"]');
      if (selection) {
        buildingUseSelect.value = selection.value;
      }
    }
  }

  if (form) {
    form.addEventListener("submit", handleFormSubmission);
  }

  function handleStatusRadio(event) {
    if (event) {
      updateStatusRadios(event.target.value, true);

      if (buildingStatusSelect && buildingStatusSelect.value === event.target.value) {
        buildingStatusSelect.value = '';
        if (buildingStatusRadiosWrapper) {
          buildingStatusRadiosWrapper.classList.remove('has-selection-value');
        }
      } else {
        if (buildingStatusSelect) {
          buildingStatusSelect.value = event.target.value;
        }
        if (buildingStatusRadiosWrapper) {
          buildingStatusRadiosWrapper.classList.add('has-selection-value');
        }
      }
    }
  }

  function handleCategoryRadio(event) {
    if (event) {
      handleBuildingServiceToBuildingStatus(event);
      updateCategoryRadios(event.target.value, true);
      if (buildingCategorySelect && buildingCategorySelect.value === event.target.value) {
        buildingCategorySelect.value = '';
        if (buildingCategoryRadiosWrapper) {
          buildingCategoryRadiosWrapper.classList.remove('has-selection-value');
        }
      } else {
        if (buildingCategorySelect) {
          buildingCategorySelect.value = event.target.value;
        }
        if (buildingCategoryRadiosWrapper) {
          buildingCategoryRadiosWrapper.classList.add('has-selection-value');
        }
      }
    }

    updateBuildingUses();
  }

  function handleBuildingServiceToBuildingStatus(event) {
    if (buildingStatusColumn && event) {
      event.target.dataset.clickDimensionsHandle === "Building_Category_2" && event.target.value === '5'
        ? buildingStatusColumn.classList.add('is-hidden')
        : buildingStatusColumn.classList.remove('is-hidden');

      if (buildingCategorySelect && buildingCategorySelect.value === event.target.value && window.innerWidth > 1000) {
        buildingStatusColumn.classList.remove('is-hidden');
      }
    }
  }

  function handleStatusSelect(event) {
    if (event) {
      updateStatusRadios(event.target.value, false);
    }
  }

  function handleCategorySelect(event) {
    if (event) {
      handleBuildingServiceToBuildingStatus(event);
      updateCategoryRadios(event.target.value, false);
    }
  }

  function updateStatusRadios(value, isRadioEvent = true) {
    if (buildingStatusRadios.length > 0) {
      [...buildingStatusRadios].forEach(radio => {
        if (radio.value === value) {
          radio.checked = true;
        }
        if (isRadioEvent && (radio.value === buildingStatusSelect.value)) {
          radio.checked = false;
        }
      });
    }
  }

  function updateCategoryRadios(value, isRadioEvent = true) {
    if (buildingCategoryRadios.length > 0) {
      [...buildingCategoryRadios].forEach(radio => {
        if (radio.value === value) {
          radio.checked = true;
        }
        if (isRadioEvent && (radio.value === buildingCategorySelect.value)) {
          radio.checked = false;
        }
      });

      updateBuildingUses();
    }
  }

  function updateBuildingUses() {
    if (buildingUseOptions) {
      let options = buildingUseOptions.querySelectorAll('[data-parent="' + buildingCategorySelect.value + '"]');

      resetBuildingUseSelect(options.length);

      [...options].forEach((buildingUse) => {
        buildingUseSelect.options[buildingUseSelect.options.length] = new Option(buildingUse.text, buildingUse.value);
        buildingUseSelect.options[buildingUseSelect.options.length - 1].setAttribute("data-redirect", buildingUse.dataset.redirect);
      });
    }
  }

  function resetBuildingUseSelect(newOptionCount = 0) {
    if (buildingUseSelect) {
      buildingUseSelect.disabled = false;
      buildingUseSelect.selectedIndex = null;
      buildingUseSelect.innerText = null;
      buildingUseSelect.options[0] = new Option('Select One', '');

      if (!newOptionCount) {
        buildingUseSelect.selectedIndex = 0;
        buildingUseSelect.disabled = true;

        // Set the submitted value to 796 and disabled select
        // Based on feedback from David in ticket #307
        if (buildingCategorySelect && buildingCategorySelect.value !== '4') {
          buildingUseSelect.options[0] = new Option('Not Applicable', 796);
        } else {
          buildingUseSelect.options[0] = new Option('Not Applicable', 620);
        }
      }
    }
  }

  function handleUseSelect(event) {
    if (event) {
      var selectedOption = event.target[event.target.selectedIndex];
      //@todo test this
      if (buildingUseSelect && buildingUseText) {
        if (buildingUseSelect.value) {
          buildingUseText.value = buildingUseSelect.value;
        }
      }
      if (formRedirectUrl) {
        formRedirectUrl.value = selectedOption.dataset.redirect;
      }
    }
  }

  function pushDataLayer() {
    let city = form.querySelector('[name="City"]').value;
    let state = form.querySelector('[name="State"]').value;
    let zipCode = form.querySelector('[name="Zip_Code"]').value;
    let buildZip = form.querySelector('[name="Build_Location_Zip_Code"]').value;
    let salesCall = 1;
    let buildCategory = null;
    let buildUse = null;
    let timeLine = null;

    if (form.querySelector('[type="radio"][name="Would_you_like_a_sales_consultant_to_call_you"]')) {
      const isChecked = form.querySelector('[type="radio"][name="Would_you_like_a_sales_consultant_to_call_you"]:checked');
      if (isChecked) {
        salesCall = form.querySelector('[type="radio"][name="Would_you_like_a_sales_consultant_to_call_you"]:checked').value;
      }
    }

    if (form.querySelector('select[name="Building_Use_2"]')) {
      const isChecked = form.querySelector('select[name="Building_Use_2"] option:checked');
      if (isChecked) {
        buildUse = form.querySelector('select[name="Building_Use_2"] option:checked').innerHTML;
      }
    } else {
      buildUse = form.querySelector('[type="hidden"][name="Building_Use_2"]').value;
    }

    if (form.querySelector('input[type="radio"][name="Building_Category_2"]') !== null) {
      const isChecked = form.querySelector('input[type="radio"][name="Building_Category_2"]:checked');
      if (isChecked) {
        buildCategory = form.querySelector('[type="radio"][name="Building_Category_2"]:checked').nextElementSibling.innerHTML;
      }
    } else {
      buildCategory = form.querySelector('[type="hidden"][name="Building_Category_2"]').value;
    }

    if (form.querySelector('[type="radio"][name="Expected_timeline_to_start"]')) {
      if (form.querySelector('[type="radio"][name="Expected_timeline_to_start"]')) {
        const isChecked = form.querySelector('[type="radio"][name="Expected_timeline_to_start"]:checked');
        if (isChecked) {
          timeLine = form.querySelector('[type="radio"][name="Expected_timeline_to_start"]:checked').nextElementSibling.innerHTML;
        }
      } else {
        timeLine = form.querySelector('[type="hidden"][name="Expected_timeline_to_start"]').value;
      }
    }

    // DataLayer update
    if (window.dataLayer) {
      window.dataLayer.push({
        'event': 'contact morton',
        'e_action': 'lead submission',
        'e_label': city + ', ' + state,
        'zip_code': zipCode,
        'sales_call': salesCall,
        'build_category': buildCategory,
        'build_use': buildUse,
        'build_zip': buildZip,
        'timeline': timeLine
      });
    }
  }

  function handleFormSubmission(e) {
    if (form.getAttribute('action') === null) {
      if (e) {
        e.preventDefault();
      }
    }

    if (form.querySelector('[name="Zip_Code"]').value.length < 5) {
      form.querySelector('[name="Zip_Code"]').parentElement.classList.add('ff-has-error');
      if (e) {
        e.preventDefault();
      }
    } else {
      form.querySelector('[name="Zip_Code"]').parentElement.classList.remove('ff-has-error');
    }
    if (form.querySelector('[name="Phone"]').value.length < 14) {
      form.querySelector('[name="Phone"]').parentElement.classList.add('ff-has-error');
      if (e) {
        e.preventDefault();
      }
    } else {
      form.querySelector('[name="Phone"]').parentElement.classList.remove('ff-has-error');
    }

    pushDataLayer();
    form.querySelector('button[type="submit"]').disabled = true;
    buildingUseSelect.disabled = false;
    form.submit();
    
  }

}
