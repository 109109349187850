import contextMenuDownload from '../utils/contextMenuDownload';
import addListener from '../utils/addListener'

const elements = document.querySelectorAll('[data-component=switchBackgroundImage]');
const breakpoints_mobile = '(max-width: 599px)';
const breakpoints_tablet = '(min-width: 600px) and (max-width: 999px)';
const breakpoints_desktop = '(min-width: 1000px) and (max-width: 1439px)';
const breakpoints_wide = '(min-width: 1440px) and (max-width: 1899px)';

function swapBackgroundSource(element, source) {
  // no flickering please
  if(element.style.backgroundImage !== `url("${source}")`)  // eslint-disable-line no-param-reassign
    element.style.backgroundImage = `url("${source}")`;     // eslint-disable-line no-param-reassign
}

function initSwitch(element) {

  let source = element.dataset.srcLarge;

  if (element.dataset.srcSmall) {
    addListener(breakpoints_mobile, (query) => {
      if (query.matches)
        swapBackgroundSource(element, element.dataset.srcSmall);
    });
  }

  if (element.dataset.srcMedium) {
    addListener(breakpoints_tablet, (query) => {
      if (query.matches)
        swapBackgroundSource(element, element.dataset.srcMedium);
    });
  }

  if (element.dataset.srcLarge) {
    addListener(breakpoints_desktop, (query) => {
      if (query.matches) {
        swapBackgroundSource(element, element.dataset.srcLarge);
      }
    });
  }

  if (element.dataset.srcWide) {
    addListener(breakpoints_wide, (query) => {
      if (query.matches) {
        swapBackgroundSource(element, element.dataset.srcWide);
      }
    });
  }

}

[...elements].forEach((element) => {
  initSwitch(element);

  if (element.dataset.customContextmenu) {
    contextMenuDownload();
  }

});

