import addListener from '../utils/addListener'

const elements = document.querySelectorAll('[data-component=hero]');
const breakpoints_mobile = '(max-width: 599px)';
const breakpoints_tablet = '(min-width: 600px) and (max-width: 999px)';
const breakpoints_desktop = '(min-width: 1000px) and (max-width: 1439px)';


function swapBackgroundSource(element, source) {
  // no flickering please
  if(element.style.backgroundImage !== `url("${source}")`)  // eslint-disable-line no-param-reassign
    element.style.backgroundImage = `url("${source}")`;     // eslint-disable-line no-param-reassign
}

function getHeroType(element) {
  return element.dataset.heroType || 'none';
}

function removeVideo(heroElement) {
  if (heroElement.querySelector('video') !== null) {
    heroElement.querySelector('video').remove();
  }
}

function createVideo(heroElement) {
  let videoElement = null;

  if (heroElement.querySelector('video') === null) {
    const videoSrc = heroElement.dataset.video;
    videoElement = document.createElement('video');
    videoElement.rel = 'stylesheet';

    videoElement.addEventListener('click', () => {
      const domElement = this.firstChild;

      if (domElement.paused) {
        // hasUserPaused = false;
        domElement.play();
      } else {
        // hasUserPaused = true;
        domElement.pause();
      }
    });

    videoElement.setAttribute('autobuffer', 'autobuffer')
    videoElement.setAttribute('autoloop', 'autoloop')
    videoElement.setAttribute('autoplay', 'autoplay')
    videoElement.setAttribute('loop', 'loop')
    videoElement.setAttribute('muted', 'muted')
    videoElement.setAttribute('src', videoSrc)
    videoElement.setAttribute('id', 'hero-video');

    heroElement.appendChild(videoElement);
  }

  return videoElement;
}

function initHeroImage(element) {

  let source = element.dataset.backgroundLarge;

  if (element.dataset.backgroundSmall) {
    addListener(breakpoints_mobile, (query) => {
      if (query.matches)
        swapBackgroundSource(element, element.dataset.backgroundSmall);
    });
  }

  if (element.dataset.backgroundMedium) {
    addListener(breakpoints_tablet, (query) => {
      if (query.matches)
        swapBackgroundSource(element, element.dataset.backgroundMedium);
    });
  }

  if (element.dataset.backgroundLarge) {
    addListener(breakpoints_desktop, (query) => {
      if (query.matches) {
        source = (getHeroType(element) === 'video') ?
          element.dataset.poster :
          element.dataset.backgroundLarge;

        swapBackgroundSource(element, source);
      }
    });
  }
}

function initHeroVideo(element) {
  addListener(breakpoints.desktop, (query) => {
    if (query.matches) {
      createVideo(element);
    } else {
      removeVideo(element);
      initHeroImage(element);
    }
  });
}

function initHero(element) {
  const heroType = getHeroType(element);

  if (
    // is.touchDevice()
    // ||
    heroType === 'image'
  ) {
    initHeroImage(element);
  } else if (heroType === 'video') {
    initHeroVideo(element);
  }
}

[...elements].forEach((element) => {
  initHero(element);
});

