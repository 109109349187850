import moment from '../../vendor/node_modules/moment/min/moment.min';
import Pikaday from '../../vendor/node_modules/pikaday/pikaday';

  const selectors = {
    pickerNodeList: document.querySelectorAll('[data-component=datepicker]'),
  };
  const pickers = {};
  let startDate;
  let endDate;

  function updateStartDate() {
    if (pickers['start'] && startDate) {
      pickers['start'].setStartRange(startDate);
      pickers['end'].setStartRange(startDate);
      pickers['end'].setMinDate(startDate);
    }
  }

  function updateEndDate() {
    if (pickers['start'] && endDate) {
      pickers['start'].setEndRange(endDate);
      pickers['start'].setMaxDate(endDate);
      pickers['end'].setEndRange(endDate);
    }
  }

  function bindDatepicker(options, input) {
    const opts = options;
    let field = input;

    if (typeof field === 'undefined') {
      field = (typeof opts.field === 'undefined' ? selectors.datepickerControl : opts.field);
    }

    return new Pikaday({
      field: field,
      format: 'M-D-YYYY',
      minDate: new Date(),
      themeClass: field.getAttribute('data-theme'),
      toString(date, format) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      },
      parse(dateString, format) {
        const parts = dateString.split('-');
        const month = parseInt(parts[0], 10) - 1;
        const day = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
      }
    });
  }

  [...selectors.pickerNodeList].forEach((field) => {
    const options = {};
    const name = field.getAttribute('name');
    pickers[name] = bindDatepicker(options, field);
  });

