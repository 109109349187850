var acc = document.getElementsByClassName("accordion");
var i;

if (acc.length > 0) {
  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel) {
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          let totalHeight = this.scrollHeight + panel.scrollHeight;
          panel.style.maxHeight = totalHeight + "px";
        }
      }
    });
  }
}
