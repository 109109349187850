let loader = document.querySelector('[data-component=loader]');

function showLoader() {
  if (loader) {
    loader.classList.remove('hidden');
  }
}

function hideLoader() {
  if (loader) {
    loader.classList.add('hidden');
  }
}

const Loader = {
  show: showLoader,
  hide: hideLoader,
};

