const bodyScrollLock = require('../../vendor/node_modules/body-scroll-lock/lib/bodyScrollLock.min');

if (bodyScrollLock) {
  
  const disableBodyScroll = bodyScrollLock.disableBodyScroll;
  const enableBodyScroll = bodyScrollLock.enableBodyScroll;
  const clearAllBodyScrollLocks = bodyScrollLock.clearAllBodyScrollLocks;
  const body = document.querySelector('body');

  if (body) {
  
    const page = document.querySelector('html');
    const triggers = document.querySelectorAll('[data-modal-trigger]');
    let modals = document.querySelectorAll('[data-component=modal]');

    document.body.addEventListener('click', function (event) {
      if (event.srcElement.closest('[data-modal-trigger]')) {
        handleTriggerTarget(event.srcElement.closest('[data-modal-trigger]'));
      }
    });

    function handleTriggerTarget(trigger) {
      let target = document.querySelector('[data-modal-target="' + trigger.dataset.modalTrigger + '"]');
      let image = target ? target.querySelector('.modal-image') : null;
      let iframe = target ? target.querySelector('.modal-iframe') : null;

      if (!target) {
        return; // Exit early if target element is missing
      }

      target.classList.toggle('is-active');

      if (trigger.hasAttribute('data-freeze')) {
        bodyScrollLock.disableBodyScroll(target);

        if (image) {
          image.src = trigger.dataset.url;
        }
        if (iframe) {
          iframe.src = trigger.dataset.url;
        }
      }

      if (trigger.hasAttribute("data-unfreeze")) {
        bodyScrollLock.enableBodyScroll(target);
        if (iframe) {
          iframe.src = trigger.dataset.url;
        }
      }

      document.onkeydown = function (e) {
        keyDown(e, target);
      }
    }

    function keyDown(e, target) {
      e = e || window.e;
      let isEscape = false;
      let modals = document.querySelectorAll('[data-component=modal]');
      let iframe = target ? target.querySelector('iframe') : null;

      if ("key" in e) {
        isEscape = (e.key === "Escape" || e.key === "Esc");
      } else {
        isEscape = (e.keyCode === 27);
      }

      if (isEscape) {
        [...modals].forEach((el) => {
          el.classList.remove('is-active');
          bodyScrollLock.enableBodyScroll(target);
          if (iframe) {
            iframe.src = '';
          }
        });
      }
    };
  }
}
