
  let svgComponents = document.querySelectorAll('[data-component="foundationComponents"]');
  let dataComponents = document.querySelectorAll('div[data-component-group]');
  let pagers = document.querySelectorAll('.foundationComponents .chevron:not(.is-inactive)');

  if (svgComponents.length > 0 && dataComponents.length > 0 && pagers.length > 0) {
    function handleComponentHover(e) {
      let hoveredComponent = this;

      [...svgComponents].forEach((el) => {
        if (el.dataset.componentGroup === hoveredComponent.dataset.componentGroup) {
          let dataComponent = document.querySelector('div[data-component-group="' + el.dataset.componentGroup + '"]');
          dataComponent.classList.add('is-active');
          el.classList.add('is-active');
        } else {
          let dataComponent = document.querySelector('div[data-component-group="' + el.dataset.componentGroup + '"]');
          dataComponent.classList.remove('is-active');
          el.classList.remove('is-active');
        }
      });

      [...dataComponents].forEach((el) => {
        if (el.dataset.componentGroup === hoveredComponent.dataset.componentGroup) {
          let svgComponent = document.querySelector('g[data-component-group="' + el.dataset.componentGroup + '"]');
          svgComponent.classList.add('is-active');
          el.classList.add('is-active');
        } else {
          let svgComponent = document.querySelector('g[data-component-group="' + el.dataset.componentGroup + '"]');
          svgComponent.classList.remove('is-active');
          el.classList.remove('is-active');
        }
      });
    }

    function handlePager(e) {
      let pager = this;
      let activeComponent = document.querySelector('div[data-component-group].is-active');
      let svgComponents = document.querySelectorAll('[data-component="foundationComponents"]');

      [...pagers].forEach((el) => {
        el.classList.remove('is-inactive');
      });

      if (pager.classList.contains('is-previous')) {
        let previousComponent = activeComponent.previousElementSibling;

        if (previousComponent) {
          let svgComponent = document.querySelector('g[data-component-group="' + previousComponent.dataset.componentGroup + '"]');

          [...svgComponents].forEach((el) => {
            el.classList.remove('is-active');
          });
          activeComponent.classList.remove('is-active');
          previousComponent.classList.add('is-active');
          svgComponent.classList.add('is-active');

          if (previousComponent.previousElementSibling === null) {
            pager.classList.add('is-inactive');
          }
        } else {
          pager.classList.add('is-inactive');
        }
      }
      if (pager.classList.contains('is-next')) {
        let nextComponent = activeComponent.nextElementSibling;

        if (nextComponent) {
          let svgComponent = document.querySelector('g[data-component-group="' + nextComponent.dataset.componentGroup + '"]');

          [...svgComponents].forEach((el) => {
            el.classList.remove('is-active');
          });
          activeComponent.classList.remove('is-active');
          nextComponent.classList.add('is-active');
          svgComponent.classList.add('is-active');

          if (nextComponent.nextElementSibling === null) {
            pager.classList.add('is-inactive');
          }
        } else {
          pager.classList.add('is-inactive');
        }
      }
    }

    [...svgComponents].forEach((el) => {
      el.addEventListener('mouseover', handleComponentHover);
    });

    [...dataComponents].forEach((el) => {
      el.addEventListener('mouseover', handleComponentHover);
    });

    [...pagers].forEach((el) => {
      el.addEventListener('click', handlePager);
    });
  }

