import htmx from "htmx.org";
import smoothScrollTo from '../utils/smoothScrollTo';
// Banner imports - pulled out of sticky.js
import stickybits from '../../vendor/node_modules/stickybits/dist/stickybits.min';
import addListener from '../utils/addListener';
import Scrollbar from '../../vendor/node_modules/smooth-scrollbar/dist/smooth-scrollbar';


htmx.on('htmx:afterSwap', function(event) {
  let zipAndDepartmentForm = null;
  let careersFilterForm = null;
  const breakpoints_mobile = '(max-width: 599px)';
  const breakpoints_tablet = '(min-width: 600px) and (max-width: 999px)';
  const breakpoints_desktop = '(min-width: 1000px) and (max-width: 1439px)';
  const breakpoints_wide = '(min-width: 1440px) and (max-width: 1899px)';
  const breakpoints_fullhd = '(min-width: 1900px)';
  const careerPanelTriggers = document.querySelectorAll('.tabs [data-component=showElementHideSiblings]');
  const filters = document.querySelectorAll('[data-id=careersFilterForm]');
  const departmentFields = document.querySelectorAll('[name=department]');
  const locationFields = document.querySelectorAll('[name=location]');
  const resultsDomWrapper = document.querySelector('[data-destination=careersResults]');
  const resultsScrollWrapper = document.querySelector('.scroll-content');
  const map = document.querySelector('.gm-map');
  const mapStyle = [
    {
      "featureType": "water",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#d3d3d3"
        }
      ]
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "color": "#808080"
        },
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#b3b3b3"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#ffffff"
        },
        {
          "weight": 1.8
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#d7d7d7"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#ebebeb"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#a7a7a7"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#efefef"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#696969"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "visibility": "on"
        },
        {
          "color": "#737373"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#d6d6d6"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {},
    {
      "featureType": "poi",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    }
  ];

  // Job Role Variables
  const jobRoleParentNode = document.querySelector('[data-job-roles-parent]');
  let jobRolesReferenceNode = '';
  let jobRolesSelectNode = '';
  if (jobRoleParentNode) {
    jobRolesReferenceNode = jobRoleParentNode.querySelector('[data-job-roles-reference]');
    jobRolesSelectNode = jobRoleParentNode.querySelector('[data-job-roles-select]');
  }

  // Job Department Banner Variables
  const jobDepartmentBannerNode = document.querySelector('[data-job-department-banner]');
  const jobDepartmentSelectNode = document.getElementById('department-selector');

  if (resultsDomWrapper) {
    let locations = resultsDomWrapper.querySelectorAll('li');
    let params = new URLSearchParams(document.location.search);
    let locationZip = params.get("location");
    let avoidMapMove = false;
    let isNewZipSearch = true;

    if (window.innerWidth >= 600) {
      Scrollbar.init(document.querySelector('#scroll-careers'), {
        'alwaysShowTracks': true,
      });
    }

    function domReady(event) {
      document.addEventListener("DOMContentLoaded", event);
      if (document.readyState === "interactive" || document.readyState === "complete" ) {
        event();
      }
    }

    function handleFilterSelect(select) {
      let submittedForm = select.target.closest('form');
      submitForm(submittedForm);
    }

    function handleInputChange(input) {
      let submittedForm = input.closest('form');
      if((input.value).length == 5) {
        submitForm(submittedForm);
      }
    }

    function replaceJobListingHtmlWithAjaxResponse(htmlString){
      resultsDomWrapper.innerHTML = htmlString;
      if (window.innerWidth <= 600) {
        mobileLoadMore();
      } else {
        Scrollbar.init(document.querySelector('#scroll-careers'), {
          'alwaysShowTracks': true,
        });
      }
    }

    function updateMapAndMarkersForLocation(){
      setLocationFields(params.get('location'));

      // Validate - there are results to populate the map?
      if (resultsDomWrapper.querySelector('[data-id]') === null){
        return false;
      }

      // Query ResultsSomWrapper for map & marker position
      let locationId = resultsDomWrapper.querySelector('[data-id]').dataset.id;
      let lat = resultsDomWrapper.querySelectorAll('[data-lat]');
      let lng = resultsDomWrapper.querySelectorAll('[data-lng]');
      let latLng = {lat: parseFloat(lat[0].dataset.lat), lng: parseFloat(lng[0].dataset.lng)};
      
      if(avoidMapMove) {
        avoidMapMove = false;
      } else {
        const mapId = map.getAttribute('id');
        const googleMap = googleMaps.getMap(mapId);
        googleMap.zoom(9).center(latLng);
      }
    }

    function handleAjaxResponse(responseText) {
      replaceJobListingHtmlWithAjaxResponse(responseText);
      updateMapAndMarkersForLocation();
      addRoleButtonListeners();
    }

    function addRoleButtonListeners() {
      const jobRolesButtons = document.querySelectorAll('[data-filterCategoryButton]');
      if (jobRolesButtons.length) {
        jobRolesButtons.forEach((button) => {
          button.addEventListener('click', (event) => {
            event.preventDefault();
            let xhr = new XMLHttpRequest();
            const zipActive = document.getElementById('selectLocationFilter').value;
            xhr.open('GET', '/ajax/careers?department=' + button.dataset.parentdepartment + '&role=' + button.dataset.filtercategorybutton + '&location=' + zipActive);
            xhr.send();

            xhr.onload = function () {
              if (xhr.readyState === xhr.DONE && xhr.status === 200) {
                handleAjaxResponse(xhr.responseText);
              }
            };
          });
        });
        
      }
    }

    function resetRoles() {
      const roleButtons = document.querySelector('.job-categories');
      if (roleButtons) {
        roleButtons.remove();
      }
    }

    function resetCategory() {
      $("#careerCategory option").each(function () {
        $(this).toggle(true)
      })
    }

    function getLocationFieldValue() {
        let result = null;
        [...locationFields].forEach((locationField) => {
          if (locationField.value && !result) result = locationField.value
        });
        return result;
    }

    function setLocationFields(locationZip) {
      if(! locationZip) locationZip = '';
    }

    function setDepartmentFields(department) {
      if(! department) {
        department = '';
      }
      [...departmentFields].forEach((departmentField) => {
        departmentField.value = department;
      });
    }

    function submitForm(submittedForm) {
      let formData = new FormData(submittedForm);
      let xhr = new XMLHttpRequest();
      let query = '';

      let locationValue = getLocationFieldValue();
      if (locationValue && !formData.has('location')) {
        formData.append('location', locationValue)
      }

      [...formData].forEach((param) => {
        query += param[0] + '=' + param[1] + '&';
      });
      params = new URLSearchParams(query);

      xhr.open('GET', submittedForm.action + '?' + params.toString());
      xhr.send();

      xhr.onload = function () {
        if (xhr.readyState === xhr.DONE && xhr.status === 200) {
          handleAjaxResponse(xhr.responseText, formData.get('department'));
        }
      };
    }

    function stripTags(html) {
      return html.replace(/(<([^>]+)>)/gi, "");
    }

    function centerMap(smartMapId,latLng) {
      googleMaps.map(smartMapId).zoom(9).center(latLng);
    }

    function resetMarkers(markers, location, map) {
      const markerKeys = Object.keys(map._markers);
      [...markers].forEach((marker, index) => {
        const markerId = markerKeys[index];
        map.setMarkerIcon(markerId, '/assets/src/images/map-dot.svg');
      });
      map.markers([], {
        markerOptions: {
          icon: {scaledSize: {width: 8, height: 8} }
        }
      });
      map.setMarkerIcon(location, '/assets/src/images/map-dot-selected.svg');
    }

    function handleMarkerClick(marker, resultsContainer, markerId, googleMap) {
      if (googleMap) {
        const markerWindow = googleMap.getInfoWindow(markerId);
        markerWindow.close();
        console.log(marker.getPosition().lat(), marker.getPosition().lng());
        googleMap.zoom(9).center({
            lat: marker.getPosition().lat(), 
            lng: marker.getPosition().lng()
        });

      const markers = Object.values(googleMap._markers);
      
        //resetMarkers(markerId, location);
        resetMarkers(markers, markerId, googleMap);

        const markerContent = markerWindow.content;
        resultsContainer.innerHTML = '';
        resultsContainer.innerHTML = markerContent;
      }
      
      const city = resultsContainer.querySelector('#location-match');
      let resultsCity = '';
      if (city) {
        resultsCity = resultsContainer.querySelector('#location-match').innerHTML;
      }
      updateFields(resultsCity);
    }

    function updateFields(marker, zip = '') {
      
      let locationZipInputs = document.querySelectorAll('input[name="location"]');

      [...filters].forEach((filter) => {
        let selects = filter.querySelectorAll('option[value="' + marker + '"]');
        [...selects].forEach((select) => {
          select.selected = true;
        });
      });

      [...locationZipInputs].forEach((locationZipInput) => {
          locationZipInput.value = zip;
      });
    }

    function handleLocationMarkerClick(marker, markerId, googleMap) {
      handleMarkerClick(marker, document.querySelector('aside[data-return-destination]'), markerId, googleMap);
      updateFields(marker, document.querySelector('.careersByLocation_box [data-zip]').dataset.zip);
      resetRoles();
      submitForm(zipAndDepartmentForm);
    }

    function handleDepartmentMarkerClick(marker, markerId, googleMap) {
      handleMarkerClick(marker, document.querySelector('.careersResults'), markerId, googleMap);
      avoidMapMove = true;
      resetCategory();
      submitForm(careersFilterForm);
    }

    function loadCareersByDepartmentMap() {
      const xhr = new XMLHttpRequest();

      xhr.open('GET', '/sprig/careersLocationsOptions');

      xhr.onload = function () {
        if (xhr.readyState === xhr.DONE) {
          if (xhr.status === 200) {
            //zipAndDepartmentSection.innerHTML = xhr.responseText;

            [...locationFields].forEach((locationField) => {
                locationField.innerHTML = xhr.responseText;
            });
          }
        }
      };

      xhr.send();

    }

    function initializeMaps() {
        setTimeout(function(){
          const mapId = map.getAttribute('id');
          const googleMap = googleMaps.getMap(mapId);
          buildMap(googleMap);
        }, 2500);
    }

    function buildMap(googleMap){
      const markers = Object.values(googleMap._markers);
      const markerKeys = Object.keys(googleMap._markers);

      markers.forEach(function(location, index){

        const markerId = markerKeys[index];

        location.addListener('click', () => {
          const mapId = googleMap.id;

          if(mapId == 'careersByDepartment_map') {
            handleDepartmentMarkerClick(location, markerId, googleMap);
          }

          if(mapId == 'careersByLocation_map') {
            handleLocationMarkerClick(location, markerId, googleMap);
          }
        });
      });

      googleMap.styles(mapStyle);

      const options = {
        styles: mapStyle,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
      };
    }

    function initializeApp() {
      
      if (typeof googleMaps === 'undefined') {
        // continue to check for googleMaps
      } else {
        clearInterval(initApp);
        locationZip = params.get("location");
        zipAndDepartmentForm = document.forms.zipAndDepartmentForm;
        careersFilterForm = document.forms.careersFilterForm;
        initializeMaps();
        loadCareersByDepartmentMap();
        addZipAndDepartmentSubmitListener();
        addCareerPageEventListeners();
        addFiltersListeners();
    
        if (locationZip) {
          const query = window.innerWidth;
          addListener(breakpoints_tablet, (query) => {
            console.log('mobile');
            if (query.matches) {
              console.log('mobile scroll');
              smoothScrollTo(document.querySelector('.careersByDepartment'), 300);
            }
          });
          addListener(breakpoints_tablet, (query) => {
            console.log('tablet');
            if (query.matches) {
              console.log('tablet scroll');
              smoothScrollTo(document.querySelector('.careersByDepartment'), 1250);
            }
          });
          addListener(breakpoints_desktop, (query) => {
            console.log('desktop');
            if (query.matches) {
              console.log('desktop scroll');
              smoothScrollTo(document.querySelector('.careersByDepartment'), 200);
            }
          });
          setLocationFields(locationZip);
          setDepartmentFields(params.get('department') ? params.get('department') : '');
          submitForm(zipAndDepartmentForm);
        }
      }
    }
    
    googleMaps.init();

    // Call the function once before starting the interval to avoid the initial delay.
    initializeApp();
    
    // Set the interval to repeat the function every 200 milliseconds (0.2 seconds).
    const initApp = setInterval(initializeApp, 200);

    function addFiltersListeners(){
      [...filters].forEach((filter) => {
        let selects = filter.querySelectorAll('select');
        let inputs = filter.querySelectorAll('input[data-behavior="autocomplete"]');

        [...selects].forEach((select) => {
          select.addEventListener('change', handleFilterSelect);
        });

        [...inputs].forEach((input) => {
          let timeout = null;

          input.onkeyup = function(e) {
            clearTimeout(timeout);
            timeout = setTimeout(function() {
                handleInputChange(input);
              },
              200);
          };
        });
      });
    }

    function addZipAndDepartmentSubmitListener(){
      zipAndDepartmentForm.addEventListener("submit", function(e){
        e.preventDefault();
        setDepartmentFields(params.get('department') ? params.get('department') : '');
        submitForm(zipAndDepartmentForm);
        $('#careerLocationInput').blur();  
        addListener(breakpoints_mobile, (query) => {
          if (query.matches) {
            smoothScrollTo(document.querySelector('.careersByDepartment'), -360);
          }
        });
        addListener(breakpoints_tablet, (query) => {
          if (query.matches) {
            smoothScrollTo(document.querySelector('.careersByDepartment'), -335);
          }
        });
        addListener(breakpoints_desktop, (query) => {
          if (query.matches) {
            smoothScrollTo(document.querySelector('.careersByDepartment'), 250);
          }
        });
        addListener(breakpoints_wide, (query) => {
          if (query.matches) {
            smoothScrollTo(document.querySelector('.careersByDepartment'), 250);
          }
        });
        addListener(breakpoints_fullhd, (query) => {
          if (query.matches) {
            smoothScrollTo(document.querySelector('.careersByDepartment'), 250);
          }
        });
      });
    }

    function purgeJobRolesDropdown(){
      while (jobRolesSelectNode.firstChild) {
        jobRolesSelectNode.removeChild(jobRolesSelectNode.firstChild);
      }
    }

    function rebuildJobRolesSelect(arrayOfOptions){
      [...arrayOfOptions].forEach((option) => {
        const clone = option.cloneNode(true);
        jobRolesSelectNode.append(clone);
      })
    }

    function filterJobRolesDropdownByDepartmentBanner(departmentSelectedHandle){
      if (jobRolesReferenceNode) {
        const referenceOptionsArray = jobRolesReferenceNode.querySelectorAll('option');
        [...referenceOptionsArray].forEach((option) => {
          var optionHandle = option.value;
          if(optionHandle == departmentSelectedHandle){
            option.setAttribute('selected', true);
          }
        });
        purgeJobRolesDropdown();

        // build array of options from jobRolesReferenceNode;
        rebuildJobRolesSelect(referenceOptionsArray);
        return false;
      }
    }

    function addCareerPageEventListeners(){
      $('#department-selector').on('change', '', function (e) {
        const departmentHandle = e.currentTarget.value.toLowerCase();
        filterJobRolesDropdownByDepartmentBanner(departmentHandle);
        setActiveCareerCategory(departmentHandle);
        submitForm(zipAndDepartmentForm);
      });

      $('input[name=location]').keyup( function () {
        if (getLocationFieldValue()) isNewZipSearch = false;
        else {
          isNewZipSearch = true;
          resetCategory()
        }
      })

      $('select[name=department]').change('click', '', function () {
        if (getLocationFieldValue()) isNewZipSearch = false;
        else {
          isNewZipSearch = true;
          resetCategory()
        }
      })

      $('#mapLocationInput').keyup(function (event) {
        $('#careerLocationInput').val('Enter Zip Code')
        $('#selectLocationFilter').val('')
        resetCategory()
      })

      $('#careerLocationInput').keyup(function (e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
          submitForm(zipAndDepartmentForm)
          $('#careerLocationInput').blur()
        } else {
          $('#mapLocationInput').val('')
        }
      })

      $('#selectLocationFilter').on('change', '', function (e) {
        $('#careerLocationInput').val('Enter Zip Code');
        $('#mapLocationInput').val('Enter Zip Code');
        submitForm(careersFilterForm);
      })

    }

    function setActiveCareerCategory(departmentHandle){
      const careerCategorySelect = document.getElementById('careerCategory');
      const careerCategoryOptions = careerCategorySelect.querySelectorAll('option');
      [...careerCategoryOptions].forEach((option, index) => {
        option.removeAttribute('selected');
        const optionHandle = option.value.toLowerCase();
        if(optionHandle == departmentHandle){
          careerCategoryOptions[index].selected = true
        }
      });
    };

    // Function to handle intersection changes
    function handleIntersection(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // The observed element is in view
          document.querySelector('[data-job-department-banner]').classList.add('is-slideup');
        } else {
          // The observed element is out of view
          document.querySelector('[data-job-department-banner]').classList.remove('is-slideup');
        }
      });
    }

    // Options for the Intersection Observer
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: 0.5 // Trigger when 50% of the observed element is in view
    };

    // Create an Intersection Observer
    const observer = new IntersectionObserver(handleIntersection, options);

    // Observe the target element
    observer.observe(document.getElementById('careersByDepartment'));

    addListener(breakpoints_mobile, (query) => {
      if (query.matches) {
        stickybits( jobDepartmentBannerNode, {
          useStickyClasses: true,
          stickyBitStickyOffset: 70
        });
      } else {
        stickybits( jobDepartmentBannerNode, {
          useStickyClasses: true,
          stickyBitStickyOffset: 85
        });
      }
    });
  }

  function mobileLoadMore() {
    
    // Find the list of jobs
    const jobsList = document.querySelector('.careersResults'); 
    // Find the scroll container inside
    const scrollContainer = jobsList.querySelector('.scroll-content');
    // Remove the fixed height on the jobsList
    jobsList.style.height = 'auto';
    // Remove the scrollroot on the scroll container
    if (scrollContainer) {
      scrollContainer.style.height = 'auto';
    }

    // Count how many job li there are
    const jobCount = jobsList.querySelectorAll('li').length;

    // Hide all jobs beyond 12
    if (jobCount > 12) {
      const jobs = jobsList.querySelectorAll('li');
      for (let i = 12; i < jobCount; i++) {
        jobs[i].style.display = 'none';
      }

      // hide any scrollbars from the scrolling plugin
      const scrollbars = document.querySelectorAll('.scrollbar-track');
      scrollbars.forEach((scrollbar) => {
        scrollbar.setAttribute('style', 'opacity: 0;');
      });

      // Check if the load more button already exists
      if (!document.querySelector('.load-more')) {
        // Show a load more button below the jobs container
        const loadMoreButton = document.createElement('button');
        loadMoreButton.textContent = 'Load More';
        loadMoreButton.classList.add('load-more');
        loadMoreButton.classList.add('button');
        loadMoreButton.classList.add('button_basic');
        loadMoreButton.classList.add('is-outlined');
        loadMoreButton.classList.add('is-red');
        jobsList.after(loadMoreButton);

        // Add a click event to the load more button
        loadMoreButton.addEventListener('click', () => {
          const hiddenJobs = jobsList.querySelectorAll('li[style="display: none;"]');
          let jobsToShow = 12; // number of jobs to show per click
          
          // Loop through hidden jobs and show them
          for (let i = 0; i < jobsToShow; i++) {
            if (hiddenJobs[i]) {
              hiddenJobs[i].style.display = 'block';
            } else {
              loadMoreButton.style.display = 'none';
              break;
            }
          }
          
          // If all jobs are shown, hide the load more button
          if (hiddenJobs.length <= jobsToShow) {
            loadMoreButton.style.display = 'none';
          }
        });
      }

    } else {
      console.log('less than 12');
      // If there are less than 12 jobs, hide the load more button
      const loadMoreButton = document.querySelector('.load-more');
      if (loadMoreButton) {
        loadMoreButton.style.display = 'none';
      }
    }
    
  }

  function loadAll() {
    // reset the jobs list to show all jobs
    const jobsList = document.querySelector('.careersResults');
    const jobs = jobsList.querySelectorAll('li');
    jobsList.removeAttribute('style');
    jobs.forEach((job) => {
      job.removeAttribute('style');
    });
    // remove the load more button
    const loadMoreButton = document.querySelector('.load-more');
    if (loadMoreButton) {
      loadMoreButton.remove
    }
    // show custom scrollbars again
    const scrollbars = document.querySelectorAll('.scrollbar-track');
    scrollbars.forEach((scrollbar) => {
      scrollbar.setAttribute('style', 'opacity: 1;');
    });
  }

  // Mobile Load More
  // Check if we are on a mobile viewport on initial load
  if (window.innerWidth <= 600) {
    mobileLoadMore();
  }
  // if the window resizes to a mobile viewport, run the mobileLoadMore function
  window.addEventListener('resize', () => {
    if (window.innerWidth < 600) {
      mobileLoadMore();
    } else {
      loadAll();
    }
  });

});