'use strict';
const bodyScrollLock = require('../../vendor/node_modules/body-scroll-lock/lib/bodyScrollLock.min');
const body = document.querySelector('body');
const transparentNav = document.querySelector('.global-header.is-transparent');
const blackNav = document.querySelector('.global-header.is-black');
const scrollDistanceElement = document.querySelector('[data-watch="nav"]');
const navigationTriggers = document.querySelectorAll('[data-component=navigation]');
const navigationClosers = document.querySelectorAll('[data-navigation-close]');
const burger = document.querySelector('button.burger');

function handleNavigationTrigger(trigger) {
  trigger.addEventListener('click', () => {
    let target = document.querySelector('[data-navigation="' + trigger.dataset.trigger + '"]');

    if(burger.classList.contains('is-active')) {
      target = document.querySelector('[data-navigation].is-active');
    } else {
      target = document.querySelector('[data-navigation="' + trigger.dataset.trigger + '"]');
    }

    if(trigger.classList.contains('is-active')) {
      bodyScrollLock.enableBodyScroll(target);
    } else {
      bodyScrollLock.disableBodyScroll(target);
    }

    burger.classList.toggle('is-active');

    body.classList.toggle('has-open-navigation');
    target.classList.toggle('is-active');

    target.hasAttribute('data-navigation-is-open') ? target.removeAttribute('data-navigation-is-open') : target.setAttribute('data-navigation-is-open', 'true');

  });
}

function handleNavigationClose(trigger) {

  trigger.addEventListener('click', () => {
    let target = document.querySelector('[data-target="' + trigger.dataset.trigger + '"]');
    let openNavigations = document.querySelectorAll('[data-navigation-is-open]');

    body.classList.remove('has-open-navigation');

    [...openNavigations].forEach((el) => {
      el.classList.remove('is-active');
      el.removeAttribute('data-navigation-is-open');
    });

    [...navigationTriggers].forEach((el) => {
      el.classList.remove('is-active');
    });

    bodyScrollLock.clearAllBodyScrollLocks();

  });
}

function handleTransparentNavSizeUpdates() {
  //don't check the current height if it is going to change
  //this will cause flickering between the different sizes
  //instead, check it's intended height in the smaller state, so it's consistent
  if(window.pageYOffset > (scrollDistanceElement.offsetHeight - 85)) {
    transparentNav.classList.add('is-black');
    transparentNav.classList.add('is-short');
    transparentNav.classList.remove('is-transparent');
  } else {
    transparentNav.classList.remove('is-black');
    transparentNav.classList.remove('is-short');
    transparentNav.classList.add('is-transparent');
  }
}

function handleBlackNavSizeUpdates() {
  if(window.pageYOffset > (scrollDistanceElement.offsetHeight - 85)) {
    blackNav.classList.add('is-short');
  } else {
    blackNav.classList.remove('is-short');
  }
}

[...navigationTriggers].forEach((el) => {
  handleNavigationTrigger(el);
});

[...navigationClosers].forEach((el) => {
  handleNavigationClose(el);
});


if(transparentNav) {
  window.addEventListener('scroll', function(e) {
    handleTransparentNavSizeUpdates();
  });
  // do this on page load, so the nav looks right when already halfway down the page
  handleTransparentNavSizeUpdates();
}

if(blackNav) {
  window.addEventListener('scroll', function(e) {
    handleBlackNavSizeUpdates();
  });
  // do this on page load, so the nav looks right when already halfway down the page
    handleBlackNavSizeUpdates();
}
