import smoothScrollTo from '../utils/smoothScrollTo';

const chevrons = document.querySelectorAll('.next-slide .chevron');
const scrollDistanceElement = document.querySelector('[data-watch="nav"]');

function findAndScrollToNext(chevron) {
  const parentNode = chevron.srcElement?.parentNode?.parentNode?.parentNode;
  const nextElement = parentNode?.nextSibling?.nextElementSibling;

  if (parentNode && nextElement) {
    const scrollToNext = nextElement;
    smoothScrollTo(scrollToNext, 0);
  } else if (parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.nextSibling?.nextElementSibling) {
    const scrollToNext = parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.nextSibling.nextElementSibling;
    smoothScrollTo(scrollToNext, -75);
  }
}

Array.from(chevrons).forEach((chevron) => {
  chevron.addEventListener('click', findAndScrollToNext);
});

